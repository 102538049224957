.assistive-chips {
    top: 10px;
    // right: 90px;
    left:30%;
    right: 20%;
    width: 50vw;
    z-index: 50;
    display: flex;
    background-color: transparent;
    border-radius: 4px;
    // height: 40px;
    align-items: center;
    // overflow: auto;
    // overflow-x: clip;

    .scroll_button_left {
        position: absolute;
        z-index: 51;
        left: 0;

        background-color: #fff;
        cursor: pointer;
        height: 36px;
        width: 36px;
        border: 1px solid #dadce0;
        border-radius: 999rem;
        top: 2px;
    }

    .scroll_button_right {
        position: absolute;
        z-index: 51;
        right: 0;
        background-color: #fff;
        cursor: pointer;
        height: 36px;
        width: 36px;
        border: 1px solid #dadce0;
        border-radius: 999rem;
        top: 2px;
    }

    .content_wrap {
        margin: 0 5%;
        overflow-x: clip;
        
        .chips_wrap {
            transition: left 0.3s ease;
            display: flex;
            // width: calc(100vw - 1050px);
            .chip_wrap {
                display: inline-block;
    
                .chip {
                    background:white;
                    margin-right: 10px;

                    .chip_icon {
                        width: 24px;
                        height: 24px;
                    }

                    .no_item_checked {
                        // #616161
                        filter: invert(40%) sepia(1%) saturate(0%) hue-rotate(245deg) brightness(92%) contrast(88%);
                    }

                    .have_item_checked {
                        // #ffffff
                        filter: invert(100%) sepia(100%) saturate(1%) hue-rotate(334deg) brightness(108%) contrast(101%);
                    }
                }
            }

            .custom-list {
                background-color: white;
                margin: 10px;
                box-shadow: rgba(60, 64, 67, 0.3) 0px 1px 2px, rgba(60, 64, 67, 0.15) 0px 1px 3px 1px;
                border-radius: 10px;
                width: max-content;

                .list_items_wrap {
                    background-color: white !important;
                    border-radius: 10px;

                    // &:first-child {
                    //     border-radius: 10px 10px 0 0;
                    // }
            
                    // &:last-child {
                    //     border-radius: 0 0 10px 10px;
                    // }

                    .custom-item {
                        padding: 5px;

                        details {
                            margin-bottom: 5px;
                        
                            summary {
                                position: relative;
                                margin-bottom: 10px;
                                display: block;
                            }
                        
                            .right-position {
                                top: 5px;
                                right: 5px;
                            }
                        
                            .icon-dropdow {
                                visibility: hidden;
                            }
                        
                            .icon-dropright {
                                visibility: visible;
                            }
                        }

                        details[open] {
                            .icon-dropdow {
                                visibility: visible;
                            }
                    
                            .icon-dropright {
                                visibility: hidden;
                            }
                        }
                    }
                }
            }

            

            // .custom-list::-webkit-scrollbar {
            //     display: none;
            // }
        }
    }
    .content_wrap::-webkit-scrollbar {
        display: none;
    }
}

@media (max-width: 1024px) {
    .assistive-chips {
        left: unset;
        right: unset;
        width: 100%;
        padding: 0 20px;
    }
}