.detail-title {margin-bottom: 10px;}
.detail-title .news-title {
  font-size: 25px;
  line-height: 50px;
  font-weight: 600;
  color: #222;
  // text-align: center;
}

.new-date {
  font-size: 16px;
  line-height: 19px;
  color: #A4A4A4;
  margin-bottom: 10px;
}

.new-desc {
  margin: 32px 0 32px 0;
  font-size: 18px;
  line-height: 30px;
  color: #333;
  font-style: italic;
  font-family: "Noto Serif",-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue","Arial","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol",serif;
  font-weight: 400;
}

.new-content > p >span{
  font-size: 18px !important;
  line-height: 30px;
  color: #333;
  // font-style: italic;
  font-family: "Noto Serif",-apple-system,BlinkMacSystemFont,"Segoe UI","Helvetica Neue","Arial","Apple Color Emoji","Segoe UI Emoji","Segoe UI Symbol",serif;
  // font-weight: 400;
}


.news .hot-new .new-date {margin-top: 10px;}
.news .other-news .new-item .new-info .view-detail {color: #1075BD;}