.header-container {
  background-color: #0077ec;
  position: relative;
  z-index: 1001;
  box-shadow: 2px 2px 2px gray;
  height: 60px;
  
  .cursor-pointer {
    cursor: pointer;
    width:25%
  }

  .navbar {

    padding: 0rem 1rem;

    .navbar-brand {
      margin-right: 0;

      .logo {
        width: 3.125rem;
        object-fit: cover;
      }
    }

    ul.navbar-nav {
      li.nav-item {
        &.padding-left-menu {
          padding-left: 0.5rem;
        }

        a.nav-link {
          font-weight: 500;
          font-size: 0.875rem;
          color: #ffffff;
          text-transform: uppercase;
          padding: 0;

          @media (min-width: 1400px) {
            font-size: 0.9375rem;
          }

          &.active, &:hover {
            color: #FFFF00;
          }
        }

        &.dropdown {
          .dropdown-menu {
            margin-top: 0.6875rem;
            background-color: transparent;
            border: 0;

            @media (max-width: 575px) {
              margin-top: 0;
              padding-top: 0;
            }

            @media (min-width: 768px) and (max-width: 991px) {
              margin-top: 0;
              padding-top: 0;
            }

            a {
              background-color: #0077ec;
              color: #ffffff;
              border-bottom: 1px solid white;
              padding: 0.5rem 1rem;
              font-size: 14px;

              &:hover {
                color: #FFFF00;
              }
            }
          }
        }
      }
    }
  }

  .dropdown-item {
    cursor: pointer;
  }
}

@media (min-width: 1400px) {
  .padding-left-menu {
    padding-left: 3rem !important;
  }
}

@media (min-width: 992px) {
  .animate {
    animation-duration: 0.3s;
    -webkit-animation-duration: 0.3s;
    animation-fill-mode: both;
    -webkit-animation-fill-mode: both;
  }
}

@keyframes slideIn {
  0% {
    transform: translateY(1rem);
    opacity: 0;
  }

  100% {
    transform: translateY(0rem);
    opacity: 1;
  }

  0% {
    transform: translateY(1rem);
    opacity: 0;
  }
}

@-webkit-keyframes slideIn {
  0% {
    -webkit-transform: transform;
    -webkit-opacity: 0;
  }

  100% {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }

  0% {
    -webkit-transform: translateY(1rem);
    -webkit-opacity: 0;
  }
}

.slideIn {
  -webkit-animation-name: slideIn;
  animation-name: slideIn;
}

.modal-user-info {
  .modal-content {
    border: 0;

    .modal-header {
      background: #0077ec;
      color: #fff;
    }

    .modal-body {
      .img-avatar {
        width: 200px;
        height: 200px;
        object-fit: cover;
      }

      .btn-change-avatar {
        color: #0077ec;
      }

      .media-file {
        position: absolute;
        top: 30px;
        left: 0;
        overflow: hidden;
        height: 100% !important;
        opacity: 0;
        cursor: pointer;
      }
    }
  }
}

@media only screen and (min-width: 1025px) {
  .custom-container {
    max-width: 960px;
    /* Optionally, you can add margin-left and margin-right properties to center the container */
    margin-left: auto;
    margin-right: auto;
  }
}