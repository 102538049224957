.look-up-planning-container {
    .left-menu {
        padding: 10px 0;

        .search-container {
            margin-top: 10px;
            margin-bottom: 10px;

            .search-by-my-position {
                top: 5px;
                border-radius: 4px;
            }
        }

        .list-select-side-menu {
            a {
                font-size: 16px;
                color: #FFFFFF;
                display: flex;
                align-items: center;
                background-color: #666666;
                text-decoration: none;
                cursor: pointer;
                border-bottom: 1px solid #ffffff;

                &:hover,
                &.active-custom {
                    background-color: #CC0902;
                }
            }

            .menu_item {
                background-color: #0077ec;
                padding: 0.5rem;
                text-transform: uppercase;
                font-weight: 500;
                color: #FFFFFF;
                border-bottom: 1px solid #ffffff;
                a {
                    color: #FFFFFF;
                    background-color: #0077ec;
                    border-bottom: 0 !important;

                    &:hover {
                        text-decoration: none;
                        color: #FFFF00;
                    }
                }
            }
        }

        .slAutocomplete {
            .select__placeholder {
                font-size: 0.875rem;
            }
        }

        .search-container {
            input {
                font-size: 0.875rem;
            }
        }
    }
}