.img_frame_for_slick {
    height: 230px;
    display: flex !important;
    justify-content: center;
    align-items: center;

    img {
        width: 100%;
        height: 100%;
    }
}

.slider_galary {
    img {
        width: 100%;
        object-fit: cover;
        max-height: 300px;
        padding: .25rem;
        border-radius: 10px;
    }
}

.scroll_button {
    position: absolute;
    transform: translate(0%, -50%);
    background-color: #fff;
    cursor: pointer;
    height: 36px;
    width: 36px;
    border: 1px solid #dadce0;
    border-radius: 999rem;
    top: 50%;
}

.scroll_button.left {
    left: 0;
}

.scroll_button.right {
    right: 0;
}