.openlayer-map-view-container {
  position: relative;
  
  #tooltip-openlayer {
    position: absolute;
    opacity: 1;
    display: none;
    padding: 5px;
    background-color: white;
    border-radius: 5px;
    z-index: 15;
    width: 200px;
    height: 7.5rem;
    z-index: 999;

    p {
        color: rgb(12, 105, 97);
        font-size: 14px;
        font-weight: 600;
        text-align: center;
        margin-bottom: 0;
    }
}

  #tooltip-container-openlayer {
    position: fixed;
    opacity: 0;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
  }
  .ol-dragbox {
    background-color: rgba(192, 68, 68, 0.4);
    border-color: rgba(100, 150, 0, 1);
  }
  #popup-container-openlayer {
    position: fixed;
    opacity: 0;
    padding: 15px;
    background-color: white;
    border-radius: 5px;
  }
  #mouse-position {
    left: 420px;
    position: fixed;
    bottom: 5px;
    color: yellow;
  }
  #title {
    font-weight: bold;
  }
}
.modal-header {
  padding: 15px;
  border-bottom: 1px solid #e5e5e5;
}
#map-scale {
  right: 50px;
  position: fixed;
  bottom: 5px;
}