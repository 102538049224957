#list_footer {
    &.list_footer {
        transition-duration: .2s;
        height: 400px;
        width: calc(100% - 395px);
        border-left: 1px solid #cdcdcd;
        background-color: #fff;
        position: absolute;
        bottom: 0;
        right: 0;
        z-index: 100;

        &.full_width {
            width: 100%;
        }

        &.hide {
            bottom: -400px;
        }

        .footer_header {
            padding: .4rem 1rem;
            background-color: #00786e;
            display: flex;
            justify-content: space-between;
            align-items: center;

            input {
                padding: 10px 10px 10px 32px;
            }

            .title {
                color: #fff;
            }

            .footer_filter {
                position: relative;

                svg {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                }
            }
        }

        .list_footer_content {
            .list-booking {
                height: 100%;
                overflow-y: auto;
            }
        }

        .cell_title {
            cursor: pointer;
            font-weight: 500;
        }
    }

    &.list_footer_mobile {
        height: 350px;
        width: 100%;
        background-color: #fff;

        .footer_header {
            padding: .4rem 1rem;

            display: flex;
            justify-content: space-between;
            align-items: center;

            input {
                padding: 10px 10px 10px 32px;
            }

            .footer_filter {
                position: relative;

                svg {
                    position: absolute;
                    left: 10px;
                    top: 10px;
                }
            }
        }

        .MuiTableCell-root {
            padding: 8px !important;
        }
    }
}

.div-pagination {
    padding: 15px;
    display: flex;
    justify-content: space-between;
    align-items: center;
    background-color: #fff;
    border-radius: 3px;
    box-shadow: 0 0 5px #cdcdcd;
    margin-bottom: 5px;

    .footer_right {
        display: flex;
    }

    @media (max-width: 767px) {
        flex-direction: column-reverse;

        .footer_right {
            display: block;
        }
    }

    .rowPerPage {
        margin-left: 10px;

        .MuiInputBase-root {
            bottom: 3px;
        }
    }

    .rowPerPage .MuiInput-underline::after {
        border-bottom: none;
    }

    .rowPerPage .MuiInput-underline::before {
        border-bottom: none;
    }

    .rowPerPage .MuiInput-underline:hover::before {
        border-bottom: none;
    }

    nav ul {
        margin: 0;
        justify-content: center;
    }

    .select-page {
        display: inline-flex;
        position: relative;
        bottom: -3px;
    }
}