.info-table {
  width: 100% !important;
  border: solid 1px #CCCCCC;
}

.info-table table {
  width: 100% !important;
  table-layout: fixed;
}

.info-table table thead {
  background-color: #EEEEEE;
}

.info-table table thead th {
  text-align: center;
  padding: 2px 8px 2px 8px;
  font-weight: normal;
  border-bottom: solid 1px #000000;
}

.info-table table tbody tr {}

.info-table table tbody tr td {
  border-bottom: solid 1px #CCCCCC;
  padding: 2px 6px 2px 6px;
  word-wrap: break-word;
}

.info-table table tbody tr td img {
  width: 100%;
  max-width: 100% !important;
}

.image-title {
  height: 40px
}

.image-show {
  max-width: 100% !important;
  height: auto;
  cursor: pointer;
  margin: auto !important;
}

.row-label {
  border-right: solid 1px #CCCCCC;
}

.custom-container-left-menu {
  background-color: white;
  padding-bottom: 40px;
  width: 395px;
  transition: all 0.5s ease;

  &.show-map-result {
    width: 90%;
  }

  .related-planning-container {
    label {
      display: block;

      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  details {
    margin-bottom: 5px;

    summary {
      position: relative;
      margin-bottom: 10px;
      display: block;
    }

    .right-position {
      top: 5px;
      right: 5px;
    }

    .icon-dropdow {
      visibility: hidden;
    }

    .icon-dropright {
      visibility: visible;
    }
  }

  details[open] {
    .icon-dropdow {
      visibility: visible;
    }

    .icon-dropright {
      visibility: hidden;
    }
  }

  .navbar-custom {
    overflow-y: auto;

    summary::-webkit-details-marker {
      display: none;
    }

    summary:focus {
      outline: 0;
    }

    #mapInfomationTab {
      padding: 10px;

      .planning-infomation {
        background-color: #cc09028c;
        color: white;
        font-size: 14px;
        line-height: 16px;
        padding: 7px;
      }

      .records-link {
        background-color: rgba(130, 199, 255, 0.5);
        color: black;
        padding: 6px 0px 8px 9px;
        font-size: 14px;
        line-height: 16px;
        text-decoration: none;
        margin-bottom: 2px;

        span {
          right: 10px;
        }
      }
    }

    .header-navbar-custom {
      background-color: #00786e;

      .btn {
        border-radius: 0;
        background-color: white;
        color: black;
        font-style: normal;
        font-weight: 500;
        font-size: 14px;
        line-height: 16px;
        padding: 10px 9px;
        border: 0;
        pointer-events: none;
      }

      .btn[class$="collapsed"] {
        background-color: #00786e;
        color: white;
        pointer-events: unset;
      }

      .btn:focus {
        box-shadow: none;
      }

      .btn-hide-left-navbar {
        right: 10px;
        background-color: unset;
        pointer-events: unset;
        top: -1px;

        .fa-angle-left {
          margin-right: -5px;
        }
      }
    }

    .header-navbar-custom-icons-style {
      right: 0px;
      width: 50px;
      background-color: rgba(255, 255, 255, 0);

      button {
        margin-top: 15px;
        background-color: white;
        border: 0px;
        width: 100%;

        .information-circle-warp {
          margin-left: 4px;
          width: 28px;
          padding: 0;
        }
      }
    }
  }

  .guide-user {
    width: 100%;
    border-top: 1px solid #c5c5c5;
    bottom: 0px;
    font-size: 14px;
    color: #747474;
    background-color: #fff;

    &_bottom_40 {
      bottom: 40px !important;
    }

    &_bottom_80 {
      bottom: 75px !important;
    }

    svg {
      cursor: pointer;
    }

    span {
      transition-duration: var(--animation-duration-left-navbar);
      cursor: pointer;
    }
  }

  .guide-user-hide {
    text-align: center;
    width: 12%;
    right: 6px;
  }


  .render-map-child-container {
    padding: 0 10px;
    margin-left: 10px;
    margin-right: 10px;
    border-bottom: 1px solid #ccc;

    .select-container {
      padding: 0 10px;
    }

    .map-result-view {
      img {
        width: 12px;

        &:hover {
          cursor: pointer;
        }

        &.img-last-reverse {
          transform: rotate(180deg);
        }
      }
    }

    table {
      &.table-map-result {
        font-size: 14px;
      }
    }

    .map-result-number {
      .export-excel {
        font-size: 20px;
        cursor: pointer;
      }
    }
  }

  ::-webkit-scrollbar {
    width: 5px;
    height: 8px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 0 0 5px #eeeeee;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background-color: #cccccc;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb:hover {
    background: #6d6e70;
  }

  &:hover {
    ::-webkit-scrollbar {
      height: 10px;
    }
  }
}

.mw-29 {
  max-width: 29vw;
}

.group_layer {
  display: flex;
  justify-content: space-between;
  align-items: center;

  svg {
    cursor: pointer;
  }
}

.render-map-child-container {
  border-bottom: 1px solid #ccc;
}