body {
  font-family: "Roboto", sans-serif;
  font-size: 1rem;
}

.error {
  font-size: 0.8rem;
  margin-top: 0.3125rem;
  color: red;
}

.required {
  &::before {
    content: "*";
    color: red;
    margin-left: 0.125rem;
  }
}

.cursor-pointer {
  cursor: pointer;
}

:focus {
  outline: none;
}

button {
  &:focus {
    outline: none;
  }
}

.container {
  @media (max-width: 1200px) {
    max-width: 95% !important;
  }
}

.opacity-4 {
  opacity: 0.4;
}

//--- Loading ---
#loading {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #000000;
  z-index: 9999;
  display: flex;
  align-items: center;

  &.loading-app {
    background-color: #000000;
    opacity: 0.6;
    transition: background-color 400ms cubic-bezier(0.23, 1, 0.32, 1) 0ms;
  }

  > .canvas {
    margin: auto;
  }

  .spinner {
    animation: spinner 1s linear infinite;
    border: solid 5px transparent;
    border-top: solid 5px #0077ec;
    border-radius: 100%;
    width: 3.125rem;
    height: 3.125rem;
    margin: 0 auto;
  }
}

@keyframes spinner {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.ol-zoom.ol-unselectable.ol-control {
  display: none;
}

//--- Equal height
.flex-row {
  display: flex;

  > [class*="col-"] {
    display: flex;
    flex-direction: column;
  }

  &.row {
    &:after,
    &:before {
      display: flex;
    }
  }
}

.flag-dropdown:focus-within {
  color: #495057;
  background-color: #fff;
  border-color: #80bdff;
  outline: 0;
  box-shadow: 0 0 0 0.2rem rgba(0, 123, 255, 0.25);
  border-radius: 4px;
}

.flag-dropdown:focus-within *:focus {
  outline: 0;
}

//--- Scrollbar
::-webkit-scrollbar {
  width: 10px;
  height: 10px;
}

::-webkit-scrollbar-track {
  box-shadow: inset 0 0 5px #eeeeee;
}

::-webkit-scrollbar-thumb {
  background-color: #cccccc;
}

::-webkit-scrollbar-thumb:hover {
  background: #6d6e70;
}
