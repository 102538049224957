.preview-content-header {
    min-height: 30px;
    padding-top: 10px;
    border-bottom: solid 1px #CCCCCC;

    .preview-modal-title {
        min-height: 30px;
        padding-left: 10px;
    }
    
    .preview-close-button {
        position: absolute;
        right: 0px;
        cursor: pointer;
        width: 30px;
        height: 30px;
    }
}



.preview-content-box {
    padding: 5px;
    text-align: center;
    position: relative;

    .image-wrapper {
        height: 50vh;
        display: flex !important;
        justify-content: center;
        align-items: center;
        
        img {
            width: 100%;
            height: 100%;
        }    
    }

    .scroll_button {
        position: absolute;
        transform: translate(0%, -50%);
        background-color: #fff;
        cursor: pointer;
        height: 36px;
        width: 36px;
        border: 1px solid #dadce0;
        border-radius: 999rem;
        top: 50%;
    }
    
    .scroll_button.left {
        left: 1%;
    }
    
    .scroll_button.right {
        right: 1%;
    }
}