.banner {

  position: relative;

  p {
    color: #fff;
  }

  .banner_bg {
    // position: absolute;
    background-image: linear-gradient(180deg, #0C3668 0%, rgba(12, 54, 104, 0.3) 100%), url('./Banner Web Pleiku.jpg');
    background-repeat: no-repeat;
    background-position: top center;
    background-size: cover;
    height: inherit;
    width: 100%;

    // display: flex;
    // justify-content: center;
    //align-items: center;
    .banner_content {
      height: inherit;
      max-width: 1400px;
      display: flex;
      margin: auto;
      align-items: center;

      .left_banner {
        padding: 0 2rem;

        .banner_title {
          margin-bottom: 3rem;

          p {
            font-size: 2rem;
            font-weight: 500;
            text-shadow: 2px 2px 5px #E09741;
          }

          .banner_title1 {
            padding-left: 8rem;
            font-size: 2.5rem;
          }
        }

        .banner_blocks {
          display: flex;

          .block_item {
            padding-right: 3.5rem;

            img {
              cursor: pointer;
            }

            p {
              cursor: pointer;
              padding-top: 0.5rem;
              text-align: center;
            }
          }
        }

        .banner_search {
          display: flex;
          width: fit-content;
          margin: 0 auto 10px;


          .search_icon {
            cursor: pointer;
            margin-right: 0.5rem;
            padding: 8px;
            background-color: #1954a7;
            border-radius: 50%;

            img {
              width: 36px;
              height: 36px;
            }

            &:hover {
              background-color: #C2E20C;
            }
          }

          .active {
            background-color: #C2E20C;
          }
        }

        .search_input {
          margin: 1rem 0 3rem 0;
          margin: auto;
          width: fit-content;

          ::placeholder {
            /* chạy tốt trên Chrome, Firefox, Opera, Safari 10.1+ */
            color: #fff;
            opacity: 1;
            /* Firefox */
          }

          .search_input_select {
            padding: 10px 0 16px 10px;
            width: 27rem;
            background-color: #f8f9fa4a;
            border: 1px solid #fff;
            border-radius: 3px;

            input {
              color: #fff !important;
            }

            .css-1wa3eu0-placeholder {
              color: #fff;

              input {
                color: #fff;
              }
            }
          }

          .search_input_coord {
            padding: 16px 15px 14px 20px;
            width: 27rem;
            background-color: #f8f9fa4a;
            border: 1px solid #fff;
            border-radius: 3px;
            color: #fff;

          }
        }
      }

      .right_banner {
        img {
          width: 100%;
        }
      }
    }
  }

  .home-content {
    // position: relative;
    position: unset;
    top: -1.875em;
    background-color: #fff;
    // .title {
    //   font-weight: bold;
    //   font-size: 22px;
    //   text-align: center;
    //   color: #333333;
    //   margin-bottom: 4px;
    //   margin-top: 25px;
    // }

    // hr {
    //   border-width: 2px;
    //   border-color: #ffce38;
    //   width: 35%;
    //   margin-top: 0;
    // }

    .content {
      // flex-wrap: nowrap;
      // overflow: auto;
      margin: 20px 0;

      .item {
        // margin-bottom: 1rem;
        margin-top: 1rem;
        padding: 0 0.8rem;
        // padding-right: 0.8em;
        // padding-left: 0.8em;

        a:hover {
          text-decoration: none;
        }

        .item-block {
          // border: 1px solid #0077ec;
          border-radius: 8px;
          box-shadow: 0 0 5px 1px #0077ec;
          padding: 10px 0 0 0;
          cursor: pointer;

          &:hover {
            box-shadow: 0 0 15px 1px #0077ec;
          }

          span {
            font-weight: bold;
            text-align: center;
            color: #0077ec;
            height: 60px;
            font-size: 20px;
            margin: 20px 10px;
            display: flex;
            justify-content: center;

            display: -webkit-box;
            overflow: hidden;
            -webkit-line-clamp: 2;
            -webkit-box-orient: vertical;
          }

          img {
            width: 70px;
            display: flex;
            align-items: center;
            margin: 20px auto;
          }
        }
      }

      .slick-dots {
        bottom: -10px;
      }

      .container {
        @media (max-width: 1200px) {
          max-width: 95% !important;
        }
      }

      .slick-prev,
      .slick-next {
        top: 47%;
        width: 10px;
        height: 40px;

      }

      @media (max-width: 767px) {
        .slick-next {
          right: -10px
        }

        .slick-prev {
          left: -10px;
        }
      }

    }

    .content::-webkit-scrollbar {
      width: 12px;
      /* Set the width of the scrollbar */
    }

    /* Track */
    .content::-webkit-scrollbar-track {
      background: #f1f1f1;
      /* Set the background color of the track */
    }

    /* Handle */
    .content::-webkit-scrollbar-thumb {
      background: #7c7c7c67;
      /* Set the color of the scrollbar handle */
      border-radius: 6px;
      /* Set the border radius of the handle */
    }

    /* Handle on hover */
    .content::-webkit-scrollbar-thumb:hover {
      background: #7c7c7c3b;
      /* Set the color of the handle on hover */
    }
  }
}

@media only screen and (max-width: 1024px) {
  .banner {
    // height: 481px;
    background: url('./Banner Web Pleiku.jpg') no-repeat top center;

    .banner_bg {
      height: inherit;

      .banner_content {
        // height: 481px;
        display: flex;
        margin: auto;
        align-items: center;

        .left_banner {
          .banner_title {
            margin-bottom: 2rem;

            p {
              font-size: 1.3rem;
              margin-bottom: 5px;
            }

            .banner_title1 {
              padding-left: 8rem;
              font-size: 3rem;
            }
          }

          .banner_search {
            display: flex;
            margin-left: 2.5rem;
            width: 24px;

            .search_icon {
              padding: 6px 8px;

              img {
                width: 20px;
                height: 20px;
              }
            }

            .active {
              background-color: #C2E20C;
            }
          }

          .search_input {
            ::placeholder {
              font-size: 14px;
            }

            p {
              font-size: 14px;
            }

            margin: 1rem 0 2rem 2.5rem;

            .search_input_select {
              padding: 3px 0 9px 6px;
              width: 20rem;
              background-color: #f8f9fa4a;
              border: 1px solid #F8F8F8;
              border-radius: 3px;
              font-size: 14px;

              input {
                color: #fff !important;
              }

              .css-1wa3eu0-placeholder {
                color: #fff;
                font-size: 14px;

                input {
                  color: #fff;
                }
              }
            }

            .search_input_coord {
              padding: 10px 9px 9px 16px;
              width: 20rem;
              background-color: #f8f9fa4a;
              border: 1px solid #F8F8F8;
              border-radius: 3px;
              color: #fff;
              font-size: 14px;

            }
          }

          .banner_blocks {
            margin-left: 2rem;

            .block_item {
              padding-right: 1.5rem;

              img {
                width: 5rem;
                display: flex;
                margin: auto;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }
      }
    }
  }
}

@media only screen and (max-width: 767px) {
  .banner {
    height: auto;
    background: url('./Banner Web Pleiku.jpg') no-repeat top center;

    .banner_bg {
      height: 995px;

      .banner_content {
        height: 795px;
        display: flex;
        margin: auto;
        align-items: center;

        .left_banner {
          .banner_title {
            margin: 2rem 0;

            p {
              font-size: 1.3rem;
              margin-bottom: 5px;
              text-align: center;
              text-shadow: 2px 2px 5px #2048EF;
            }

            .pl-5 {
              padding-left: 0 !important;
            }

            .banner_title1 {
              padding-left: 0 !important;
              font-size: 2.5rem;
            }
          }

          .banner_search {
            margin: 0;
          }

          .search_input {
            margin: 1rem 0 3rem 0;
          }

          .banner_blocks {
            margin-left: 0;

            .block_item {
              padding-right: 2.3rem;

              img {
                width: 5rem;
                display: flex;
                margin: auto;
              }

              p {
                font-size: 14px;
              }
            }
          }
        }

      }
    }
  }
}