.general-planning-map-view-container {
  overflow: hidden;
  
    .left-custom-navbar {
        top: 0px;
        min-width: 320px;
        height: 100%;
        left: 0px;
        transition-duration: var(--animation-duration-left-navbar);
        z-index: 10;
    
        &.show-map-result {
          width: 90%;
        }
    
        .custom-container {
          background-color: #00786E;
          padding-bottom: 40px;
    
          .navbar-custom {
            .header-navbar-custom {
              padding-top: 3px;
              background-color: #00786e;
    
              .btn {
                border-radius: 0;
                background-color: white;
                color: black;
                font-style: normal;
                font-weight: 500;
                font-size: 14px;
                line-height: 16px;
                padding: 10px 9px;
                border: 0;
                pointer-events: none;
              }
    
              .btn[class$="collapsed"] {
                background-color: #00786e;
                color: white;
                pointer-events: unset;
              }
    
              .btn:focus {
                box-shadow: none;
              }
    
              .btn-hide-left-navbar {
                right: 0px;
                padding-left: 15px;
                padding-right: 15px;
                background-color: unset;
                pointer-events: unset;
              }
            }
    
            .header-navbar-custom-icons-style {
              right: 0px;
              width: 50px;
              background-color: rgba(255, 255, 255, 0);
    
              button {
                margin-top: 15px;
                background-color: white;
                border: 0px;
                width: 100%;
    
                .information-circle-warp {
                  margin-left: 4px;
                  width: 28px;
                  padding: 0;
                  padding-bottom: 2px;
                }
              }
            }
          }
    
          .guide-user {
            padding-top: 12px;
            box-sizing: border-box;
            width: 90%;
            border-top: 1px solid #c5c5c5;
            bottom: 0px;
            right: 15px;
            height: 40px;
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 16px;
            color: #747474;
            transition-duration: var(--animation-duration-left-navbar);
    
            svg {
              cursor: pointer;
            }
    
            span {
              transition-duration: var(--animation-duration-left-navbar);
              cursor: pointer;
            }
          }
    
          .guide-user-hide {
            text-align: center;
            width: 12%;
            right: 6px;
          }
        }
    
        &.hide-left-navbar {
          .show-map-result {
            width: 395px;
            max-width: 29vw;
          }
        }
      }

    .custom-position-filter-infomation-popup {
        top: 20px;
        right: 120px;
    }

    .map-tools-container {
        .map-tool-panel-container {
            top: 50px;
            right: 10px;
        }
    }
}